.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: navy;
}

.siderStyling {
  height: 100vh;
  background-color: white;
  border-top-right-radius: 30px;
  padding: 0.8em;
}

.fontWhite{
  color: white
}

.fontBlack{
  color: black
}

.creditCardStyling{
  width: 90%;
  border-radius: 10px;
  height: 150px;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #0D5AE5;
  text-align: center,
}
.show-on-mobile-portrait{
  display: none;
}


@media
only screen and (min-width: 240px) and (max-width: 520px),
only screen and (min-device-width: 240px) and (max-device-width: 520px) {
  .hide-on-mobile-portrait {
    display: none !important;
  }
  .show-on-mobile-portrait {
    display: inline;
  }
  .list-on-mobile{
    margin: -25px;
  }


}



@media
only screen and (min-width: 240px) and (max-width: 520px),
only screen and (min-device-width: 240px) and (max-device-width: 520px) {
  .hide-on-mobile-portrait {
    display: none !important;
  }
  .siderWidth {
    width: 300px !important
  }
}

.white {
  color: white;
}

.ant-checkbox-inner {
  border-radius: 10px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background-color: transparent;
}

.text-align-last-center {
  text-align-last: center;
}

.ant-message .anticon {
  top: -3px;
}

input.ant-input.form-field {
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
}

.password-field.ant-input.form-field {
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px;
}

div.ant-input-number.form-field {
  background: transparent;
}

.transparent {
  background-color: transparent;
}

.dark-blue {
  color: #1063E7;
;
}

.flex {
  display: flex;
}

.form-field {
  margin-top: 10px;
  width: -webkit-fill-available;
  height: 85px;
  border: 0;
  border-radius: 10px;
  background-color: rgb(239, 239, 239) !important;
}

.auth-footer-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #494949;
}
.fontWeight300 {
  font-weight: 300;
}

.auth-footer-text-placement {
  place-content: center;
  margin-top: 180px;
}

.secondary-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.05em;
  color: #8C8C8C;
}

.vertical-descriptions {
.ant-descriptions-row > td{
  display: block;
}
}

.ant-descriptions-title{
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 48px;
  color: #8C8C8C;
}

span.ant-descriptions-item-label{
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #8C8C8C;
  width: 50%;
}

main.ant-layout-content {
  padding: 0 1em;
}

.full-width-description {
span.ant-descriptions-item-label{
  width: 100%;
}
}

span.ant-descriptions-item-content{
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #1F263E;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  border-bottom: 1px solid;
  background: transparent;
}

.filter-select{
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 30px;
    border: 1px solid;
  }
  .ant-select-selection-placeholder {
    color : black;
  }
}

.bold {
  font-weight: bold;
}

.mediumFont {
  font-family: 'Poppins-Medium';
}

.boldFont {
  font-family: 'Poppins-Bold';
}

.ant-table {
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  font-size: 12px !important;
  font-family: 'Poppins';
  background: #fff;
}

.ant-table-thead > tr > th {
  text-align: center;
}

.custom-list .listMeta {
  .ant-list-item-meta-description {
    line-height: 8px;
  }
  a {
    font-size: 12px;
  }
  .responsive-description {
    font-size: 12px;
  }
}

.ant-modal-content {
  border-radius: 10px;
}
.item-wrapper {
  transition: all .2s ease-in-out;
  -webkit-box-shadow: 0px 2px 10px 2px rgba(117,117,117,0.2);
  -moz-box-shadow: 0px 2px 10px 2px rgba(117,117,117,0.2);
  box-shadow: 0px 2px 10px 2px rgba(117,117,117,0.2);
  margin-bottom: 24px;
  border-radius: 8px;
  padding: 24px;
  background: white;
}

.item-wrapper:hover {
  margin-bottom: 24px;
  transform: scale(1.01,1.01);
  box-shadow: 0px 2px 16px 10px rgba(117,117,117,0.3);
  -moz-box-shadow: 0px 2px 16px 10px rgba(117,117,117,0.3);
  -webkit-box-shadow: 0px 2px 16px 10px rgba(117,117,117,0.3);
}

div.ant-list-item-meta-content {
  align-self: center;
  width: inherit;
}

.selectable {
  cursor: pointer;
}

.listMeta {
  margin-left: 15px;
  margin-right: 5px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.padding-xs {
  padding: .25em;
}

.padding-sm {
  padding: .5em;
}

.padding-md {
  padding: 1em;
}

.padding-lg {
  padding: 1.5em;
}

.padding-xl {
  padding: 3em;
}

.padding-x-xs {
  padding: .25em 0;
}

.padding-x-sm {
  padding: .5em 0;
}

.padding-x-md {
  padding: 1em 0;
}

.padding-x-lg {
  padding: 1.5em 0;
}

.padding-x-xl {
  padding: 3em 0;
}

.padding-y-xs {
  padding: 0 .25em;
}

.padding-y-sm {
  padding: 0 .5em;
}

.padding-y-md {
  padding: 0 1em;
}

.padding-y-lg {
  padding: 0 1.5em;
}

.padding-y-xl {
  padding: 0 3em;
}

.padding-top-xs {
  padding-top: .25em;
}

.padding-top-sm {
  padding-top: .5em;
}

.padding-top-md {
  padding-top: 1em;
}

.padding-top-lg {
  padding-top: 1.5em;
}

.padding-top-xl {
  padding-top: 3em;
}

.padding-right-xs {
  padding-right: .25em;
}

.padding-right-sm {
  padding-right: .5em;
}

.padding-right-md {
  padding-right: 1em;
}

.padding-right-lg {
  padding-right: 1.5em;
}

.padding-right-xl {
  padding-right: 3em;
}

.padding-bottom-xs {
  padding-bottom: .25em;
}

.padding-bottom-sm {
  padding-bottom: .5em;
}

.padding-bottom-md {
  padding-bottom: 1em;
}

.padding-bottom-lg {
  padding-bottom: 1.5em;
}

.padding-bottom-xl {
  padding-bottom: 3em;
}

.padding-left-xs {
  padding-left: .25em;
}

.padding-left-sm {
  padding-left: .5em;
}

.padding-left-md {
  padding-left: 1em;
}

.padding-left-lg {
  padding-left: 1.5em;
}

.padding-left-xl {
  padding-left: 3em;
}

.margin-xs {
  margin: .25em;
}

.margin-sm {
  margin: .5em;
}

.margin-md {
  margin: 1em;
}

.margin-lg {
  margin: 1.5em;
}

.margin-xl {
  margin: 3em;
}

.margin-x-xs {
  margin: .25em 0;
}

.margin-x-sm {
  margin: .5em 0;
}

.margin-x-md {
  margin: 1em 0;
}

.margin-x-lg {
  margin: 1.5em 0;
}

.margin-x-xl {
  margin: 3em 0;
}

.margin-y-xs {
  margin: 0 .25em;
}

.margin-y-sm {
  margin: 0 .5em;
}

.margin-y-md {
  margin: 0 1em;
}

.margin-y-lg {
  margin: 0 1.5em;
}

.margin-y-xl {
  margin: 0 3em;
}

.margin-top-xs {
  margin-top: .25em;
}

.margin-top-sm {
  margin-top: .5em;
}

.margin-top-md {
  margin-top: 1em;
}

.margin-top-lg {
  margin-top: 1.5em;
}

.margin-top-xl {
  margin-top: 3em;
}

.margin-right-xs {
  margin-right: .25em;
}

.margin-right-sm {
  margin-right: .5em;
}

.margin-right-md {
  margin-right: 1em;
}

.margin-right-lg {
  margin-right: 1.5em;
}

.margin-right-xl {
  margin-right: 3em;
}

.margin-bottom-xs {
  margin-bottom: .25em;
}

.margin-bottom-sm {
  margin-bottom: .5em;
}

.margin-bottom-md {
  margin-bottom: 1em;
}

.margin-bottom-lg {
  margin-bottom: 1.5em;
}

.margin-bottom-xl {
  margin-bottom: 3em;
}

.margin-left-xs {
  margin-left: .25em;
}

.margin-left-sm {
  margin-left: .5em;
}

.margin-left-md {
  margin-left: 1em;
}

.margin-left-lg {
  margin-left: 1.5em;
}

.margin-left-xl {
  margin-left: 3em;
}

.zero-margin {
  margin: 0;
}

.full-width {
  width: 100%;
}

li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected::after {
  border-right: white;
}

.ant-menu-inline.ant-menu-item::after {
  border-right: none;
}

span.ant-avatar.ant-avatar-lg.ant-avatar-circle.ant-avatar-image {
  width: 90px;
  height: 90px;
}

@import '~antd/dist/antd.css';
@import './assets/styles/spacing.scss';
@import './assets/styles/alignment.scss';
