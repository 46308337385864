.float-right {
  float: right;
}
.float-left {
  float: left;
}

.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
